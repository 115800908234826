import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {RegisterComponent} from "./register/register.component";
import {LoginComponent} from "./login/login.component";
import {AuthCanActivate} from "./shared/auth.guard";
import {GuestCanActivate} from "./shared/guest.guard";
import {AuthResourceService} from "./shared/auth-resoruce.service";
import {ValidatorModule} from "../form-validator/validator.module";
import {ConfirmComponent} from "./confirm/confirm.component";
import {SendComponent} from "./reset-password/send/send.component";
import {ResetComponent} from "app/auth/reset-password/reset/reset.component";
import {RecaptchaModule} from 'ng-recaptcha';
import {NotLowCanActivate} from "./shared/low-plan.guard";
import {ConfirmInvitationComponent} from "./confirm-invitation/confirm-invitation.component";
import {ConfirmInvitationAccessComponent} from "./confirm-invitation-access/confirm-invitation-access.component";
import { TurnstileComponent } from './login/turnstile.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ValidatorModule,
    RecaptchaModule
  ],
  declarations: [
    RegisterComponent,
    TurnstileComponent,
    LoginComponent,
    ConfirmComponent,
    ConfirmInvitationComponent,
    ConfirmInvitationAccessComponent,
    SendComponent,
    ResetComponent
  ],
  providers: [
    AuthCanActivate,
    GuestCanActivate,
    NotLowCanActivate,
    AuthResourceService
  ],
  exports: [TurnstileComponent] 
})
export class AuthModule {}
