import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

import {Title} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {TriallinesResourceService} from "../shared/triallines-resoruce.service";

import {Trialline} from "../../shared/trialline.model";
import {User} from "../../../auth/shared/user.model";
import {AddCardComponent} from "../../profile/billing/add-card/add-cart.component";

declare var jQuery: any;

@Component({
  selector: 'app-create_trialline',
  templateUrl: './create-trialline.component.html',

})
export class CreateTriallineComponent implements OnInit, AfterViewInit, OnChanges
{
  @Input() user: any;
  @Output() onCreated = new EventEmitter<Trialline>();
  @ViewChild(AddCardComponent, { static: true }) child:AddCardComponent;


  errors: any = {};
  disable = false;
  agree =false;
  futureTimeline: any = {};

  // date format array
  dateFormatList = [
    {
      'key': 'MDY',
      'value': 'MM/DD/YYYY'
    },
    {
      'key': 'DMY',
      'value': 'DD/MM/YYYY'
    },
    {
      'key': 'YMD',
      'value': 'YYYY/MM/DD'
    }
  ];

  constructor(
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute, public router: Router,
    protected triallinesResourceService: TriallinesResourceService
   ) {
      title.setTitle('Trialline | Create')
  }

  ngOnChanges() {
    
     console.log('I am changed');
  }  

  ngOnInit(): void { 
    //console.log('userdata',this.user.id)
    this.triallinesResourceService.getLastCreatedTrialline(this.user.id).subscribe((response: any) => {
      let lastTrialline = response.lastTrialline;
      
      if(lastTrialline.for_future_timelines == '1' && lastTrialline.trialline_date_format) {
        this.futureTimeline = {
            'trialline_date_format' : lastTrialline.trialline_date_format,
            'for_future_timelines' : lastTrialline.for_future_timelines
        };
        console.log('after query run ', this.futureTimeline);
      } else {
        this.futureTimeline = {};
      }

      console.log('last Trialline: ', response.lastTrialline)
      console.log('this.futureTimeline: ', this.futureTimeline)
    }, (error: any) => {
      console.log(error);
    });

  }

  ngAfterViewInit() {

    

  }

  createTrialline(form: NgForm)
  {
    this.disable = true;

    this.triallinesResourceService.createTrialline(form.value).toPromise()
      .then((trialline: Trialline) => {
        form.reset();
        jQuery('#newTrialLine').modal('hide');
        this.onCreated.emit(trialline);
        this.disable = false;

        this.toasts.success('TrialLine successfully created');
      })
      .catch((error) => {
        this.disable = false;
        if (error.status == 422) {
          let errorData = error.error;
          this.errors = errorData.data;
        } else if (error.status == 403) {
          this.toasts.error(error.error.error.message);
          if (!this.user.pm_last_four) {
            jQuery('#newTrialLine').modal('hide');
            jQuery('#add-card-modal').modal('show');
          }
        } else {

          this.toasts.error(error);
        }
      });
  }

  cardAdded(token) {
    jQuery('#add-card-modal').modal('hide');
  }

  resetForm(form) {
    // console.log('reset form');
    form.reset();
  }
}
