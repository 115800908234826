
import {catchError, map} from 'rxjs/operators';
import {Injectable} from "@angular/core";

import {Observable, throwError} from 'rxjs';


import {RestService} from "../../../rest/shared/rest.service";
import {HttpResponse} from "@angular/common/http";

@Injectable()
export class CouponResourceService
{
  constructor(public http: RestService) {}

  getCouponByName(reqData): Observable<any>
  {
      return this.http.get('profile/couponbyname/' + encodeURIComponent(reqData)).pipe(
         map((response: any) => {
            return response;
      }));
  }

  getSubscriptionDiscounts(): Observable<any>
  {
    
    return this.http.get('profile/subscription/discounts').pipe(
      map((response: any) => {
        
        return response;
      }));

  }
  
}

