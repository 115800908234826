import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
  Injectable
} from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpResponse } from "@angular/common/http";
import { HttpClient } from '@angular/common/http';
import { Event } from '../../main/shared/event.model'
import { TriallineNavigationComponent } from "./navigation/navigation.component";
import { env } from '../../../../.env';
import { Trialline } from '../../main/shared/trialline.model';
import * as $ from 'jquery';
import { SharedService } from '../../../app/shared/shared.service';
import { FilterToTemplatePipe } from '../shared/filterToTemplate.pipe';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { Observable, Observer, interval } from "rxjs";

import { TriallineResourceService } from '../../../app/main/trialline/shared/trialline-resoruce.service'
import { file } from 'jszip';

declare var vis: any;
declare var jQuery: any;
declare var moment: any;

@Component({
  selector: 'app-shared-trialline',
  templateUrl: './trialline.component.html',
  styleUrls: ['./trialline.component.scss'],
})
export class TriallineComponent implements OnInit, AfterViewInit, OnChanges {

  @Injectable()

  @Input() events: Event[];
  @Input() trialline: Trialline;
  @Input() password: string;
  @Input() offsetHeight: number;
  @Input() editButton: boolean;
  @Input() commentsButton: boolean;
  @Output() onEdit = new EventEmitter<number>();
  @Output() onMore = new EventEmitter<any>();


  @ViewChild('visualization', { static: true }) el: ElementRef;
  @ViewChild(TriallineNavigationComponent, { static: true }) navigation: TriallineNavigationComponent;

  public publicPath = env.publicFolderUrl;
  public apiPath = env.apiUrl;
  public idVisualisation: string;
  defaultThumbnail: string = '/images/start-event/trialline-event-thumb.jpg';
  defaultThumbnail1: string = '';

  base64Image: any;
  base64BackgroundImage: any;
  metaDescription: any;
  timeline: any;
  status: any;
  minDate: any = 0;
  maxDate: any = 0;
  eventsRef: any = [];
  zoomMin: number = 7100;

  hyphenDash: string = '';

  constructor(
    private element: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    protected sharedService: SharedService,
    private filterToTemplate: FilterToTemplatePipe,
    private titleService: Title, private metaService: Meta,
    protected triallineResourceService: TriallineResourceService,
  ) {

  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    let events = changes['events'].currentValue;
    if (this.timeline) {
      this.minDate = 0;
      this.maxDate = 0;
      this.renderAndSetItems(events);
    }

  }

  @HostListener('document:keydown', ['$event'])
  arrowNav(e: any) {

    // space and arrow keys
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1 && (!(document.getElementsByTagName("ckeditor").length > 0))) {
      e.preventDefault();
      switch (e.which) {
        case 37: // left
          this.moveWindow(-0.3);
          break;
        case 39: // right
          this.moveWindow(0.3);
          break;
        case 38: // up
          this.zoomPercent(0.3);
          break;
        case 40: // down
          this.zoomPercent(-0.3);
          break;
        default:
          return; // exit this handler for other keys
      }
    }
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img: HTMLImageElement = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {

    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    const dataURL: string = canvas.toDataURL("image/png");

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  public convertImgUrltoBase64(image_encrypt_id) {
    let imageUrl = this.apiPath + 'filesystem/' + image_encrypt_id;
    let image = '';
    this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {

      return "data:image/png;base64," + base64data;
    });
    return image;
  }


  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {

        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  async getBase64FromUrl(url: string) {

    const data = await fetch(url);

    const blob = await data.blob();



    if (data.status !== 200) {

      return null;

    }



    return new Promise((resolve) => {

      const reader = new FileReader();

      reader.readAsDataURL(blob);

      reader.onloadend = () => {

        resolve(reader.result);

      }

    });

  }

  getBase64FromUrlNewFunction = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  ngOnInit() {

    this.base64Image = '';
    if (this.trialline) {
      if (this.trialline.background_file) {
        let imageUrl = this.apiPath + 'filesystem/' + this.trialline.background_file.encrypt_id;
        //console.log('Image Url', imageUrl);
        this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
          this.base64Image = "data:image/png;base64," + base64data;
        });
      }
    }

    if (this.password) {
      this.idVisualisation = this.password;
    } else {
      this.idVisualisation = '1'
    }
    if (typeof this.editButton === 'undefined') this.editButton = true;
    if (typeof this.commentsButton === 'undefined') this.commentsButton = true;
    let items = new vis.DataSet([]);
    let self = this;


    // let thumbImage = '';
    // this.getBase64ImageFromURL(item.thumbnail.src).subscribe(base64data  => {                
    //   thumbImage = "data:image/png;base64," + base64data;      
    // });




    this.timeline = new vis.Timeline(this.el.nativeElement, items,
      {
        zoomable: true,
        autoResize: true,
        height: "100%",
        stack: true,
        align: 'left',
        dataAttributes: ['id'],
        template: function (item, element, data, visualisation) {
          //console.log('item object printing', item)

          let customColumns = '';

          // if ( item.custom_columns){
          //   item.custom_columns.forEach(item =>{
          //     customColumns +=   ((item.value)?
          //         '<a class="btn btn-default btn-xs attached-btn" id="custom_columns-button_' + self.idVisualisation + '">' +
          //         '<img src="'+item.image_url+'" alt="" style="width: 20px">' +
          //         '<span class="badge">' + 1 + '</span>' : '' +
          //         '</a>'
          //     )
          //   });
          // }

          let margin = '';
          let timepanel = '';
          let timeStart = '';
          let timeEnd = '';
          let onlydate = '';
          let datepanel = '';
          let date;
          let divbindnonecategory;
          let backgroundColor;


          if (item.has_times == true || item.has_times_end == true) {
            margin = '-58px';
          } else {
            margin = '-29px';
          }
          if (item.end_date == false){
            item.times_end = '<div style="display: none;"></div>'
          }
          if (item.end_date != null) {
            datepanel = '<div  class="ticket-header-label " style="margin-top:' + margin + ';background-color:'
              + item.background + ';height: 26px;font-size: 14px;padding:4px;">' +
              item.formatStartDate + '-' + date + '</div>';

          } else {
            onlydate = '<div class="ticket-header-date" style="font-size: 13px; color:#000; font-weight: bold;">' + item.formatStartDate + '</div>';

          }
          if(item.title.length > 22) {
            item.title = item.title.substring(0, 22)+'...';
          } else {
            item.title = item.title;
          }
          if (item.category_name == 'No Category') {
            divbindnonecategory = '';
            backgroundColor = '<div class="ticket" style=" background-color: #0E69A5 ">';
          } else {
            divbindnonecategory = '<div class="ticket-header-label" style=" color: ' + item.background + '">' +
              item.category_name + '</div>';
            backgroundColor = '<div class="ticket" style=" background-color: ' + item.background + '">';
          }

          if (item.real_end_date) {
            self.hyphenDash = '<i class="fa fa-arrow-right fa-lg icon-gray" aria-hidden="true"></i>';
          } else {
            self.hyphenDash = '';
          }

          if (item.times_start) {
            item.times_end = item.times_end;
          } else {
            item.times_end = '';
          }
          //item.backsideimage
          //  if(item.backsideimage == 'undefined') {
          //   item.backsideimage = item.thumbnail.src;
          //  } else {
          //   item.backsideimage = item.backsideimage;
          //  }
          //item.thumbnail.src 
          console.log('item arr:',item)
          return '<div>'  + backgroundColor +
            '<div class="w-100"><div class="ticket-header">' + '<div class="content-card-heading" title="' + item.category_name + '" >' +divbindnonecategory + '</div>'+
            '<div class="ticket-header-date " style="display: flex; align-items: flex-start; font-size: 18px; color:#000; font-weight: bold; padding-top: 3px; right: 10px; "><div class="date-calendar"><i class="fa fa-calendar-o" aria-hidden="true"></i></div> <div class="time-text"><div class="fs-lg">' + self.triallineCustomDateFormat(self.trialline.trialline_date_format, item.real_start_date, item.ifStartDays) + '</div>' +
            item.times_start + '</div>' +  self.hyphenDash +'<div class="time-text"><div class="fs-lg">' + self.triallineCustomDateFormat(self.trialline.trialline_date_format, item.real_end_date, item.ifEndDays) +  '</div>' + item.times_end + '</div></div></div>' +
            '<div  class="ticket-body clearfix">' +
            (item.thumbnail_visible ? '<div class="ticket-body-img img_lazy"><img class="lazyload" data-src="' + item.thumbnail.src + '"></div>' : '') +
            '<div class="ticket-body-text" style="display: grid">' +
            '<h4>' + item.title + '</h4>' +
            '<div class="text text-item-description"><p>'
            + item.description +
            '</p></div>' +
            // (self.editButton && !self.trialline.parent_trialline_id ?   '<div class="btn-wrappper"> <a id="edit-button_' + self.idVisualisation + '" class="btn btn-default btn-xs" title="Edit"><i class="fa fa-pencil" aria-hidden="true"></i><span class="text_hover">Edit </span></a>' : '') +
            '<div class="control" data-event-id="' + item.id + '" style=" color: ' + item.background + '">' +
            (self.editButton && !self.trialline.parent_trialline_id ? ' <a id="edit-button_' + self.idVisualisation + '" class="btn btn-default btn-xs" style=" padding: 2px 12px" title="Edit"><i class="fa fa-pencil" aria-hidden="true"></i><span class="text_hover">Edit</span></a>' : '') +
            '<a id="more-button_' + self.idVisualisation + '" class="btn btn-default btn-xs more-btn" title="More"><i class="fa fa-ellipsis-h"></i></a></div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '<div class="ticket-body-control">' +
            '<div class="control" data-event-id="' + item.id + '" style=" color: ' + item.background + '">' +
            (item.files_count ?
            '<a class="btn btn-default btn-xs attached-btn" title="Attachments" id="attachments-button_' + self.idVisualisation + '">' +
            '<i class="fa fa-paperclip fa-2x" aria-hidden="true"></i>' +
            '<span class="badge">' + item.files_count + '</span>' +
            '</a>': '') +
            /* tag */
            (item.tags_count ?
            '<a class="btn btn-default btn-xs attached-btn" title="Tags" id="tags-button_' + self.idVisualisation + '">' +
            '<i class="fa fa-tags fa-2x stroke-transparent" aria-hidden="true"></i>' +
            '<span class="badge">' + item.tags_count + '</span>' +
            '</a>': '') +
            // 
            
            (item.comments_count ?
              '<a class="btn btn-default btn-xs comment-btn" title="Comments" id="comments-button_' + self.idVisualisation + '">' +
              '<i class="fa fa-comments fa-2x stroke-transparent" aria-hidden="true"></i>' +
              '<span class="badge">' + item.comments_count + '</span>' +
              '</a>' : '') +
            (item.bill_cost_count ?
              '<a class="btn btn-default btn-xs attached-btn" title="BillCosts" id="bill_cost-button_' + self.idVisualisation + '">' +
              '<i class="fa fa-usd fa-2x" aria-hidden="true"></i>' +
              '<span class="badge">' + item.bill_cost_count + '</span>' +
              '</a>' : '') +     
            // /* End */
            // ((item.custom_columns)?
            //     customColumns : ''
            // )+
            '</div>' +
            '</div>' +
            '</div>'


        }
      });
    this.timeline.on('rangechanged', function (s) {
      self.sharedService.StartTime = s.start;
      self.sharedService.EndTime = s.end;
    });
    this.timeline.redraw();
    this.renderAndSetItems();
    this._changeDetectorRef.detectChanges();

  }

  triallineCustomDateFormat(trialline_date_format, currentDateFormat, checkedDays, hyphenSign = '') {
    // MM/YYYY MM/DD/YYYY
    if (trialline_date_format && currentDateFormat) {
      let fullDate;

      fullDate = currentDateFormat.split('/');

      // console.log('currentDateFormat:',currentDateFormat)
      // console.log('trialline_date_format:',trialline_date_format)
      // console.log('fullDate.length:',fullDate.length)
      // console.log('checkedDays:',checkedDays)
      // console.log('hypenSign:',hyphenSign)

      switch (trialline_date_format) {
        case 'MDY':
          if (checkedDays) {
            return hyphenSign + fullDate[0] + '/' + fullDate[2];
          } else {
            return hyphenSign + fullDate[0] + '/' + fullDate[1] + '/' + fullDate[2];
          }
          break;
        case 'YMD':
          if (checkedDays) {
            return hyphenSign + fullDate[2] + '/' + fullDate[0];
          } else {
            return hyphenSign + fullDate[2] + '/' + fullDate[0] + '/' + fullDate[1];
          }
          break;
        case 'DMY':
          if (checkedDays) {
            return hyphenSign + fullDate[0] + '/' + fullDate[2];
          } else {
            return hyphenSign + fullDate[1] + '/' + fullDate[0] + '/' + fullDate[2];
          }
          break;
        default:
          return currentDateFormat;
          break;
      }

    } else {
      return currentDateFormat;
    }

  }

  moveToEvent(startDate) {
    this.timeline.moveTo(startDate)
  }

  ngAfterViewInit() {
    let self = this;
    let $visTimeline = jQuery('.vis-timeline');
    let container = jQuery('#visualization_' + this.idVisualisation)[0];
    let setContainerHeight = function (c) {
      let offset = self.offsetHeight || 0;
      let $zoomWidget = jQuery('.widget-zoom');
      let zoomWidgetHeight = $zoomWidget.height();
      let windowHeight = jQuery(window).height();
      c.style.height = (windowHeight - offset) + 'px';
      $zoomWidget.css('top', ((windowHeight - 100) / 2) - (zoomWidgetHeight / 2));
      // if (self.timeline) {
      //   self.timeline.redraw();
      // }
    };

    setContainerHeight(container);
    jQuery(window).resize(function (e) {
      let height = $(this).height();
      let topHeight = height - 36;
      if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null) {
        $('.trial-line-navigation').css({ 'top': topHeight + 'px' })
        $('body').css({ 'height': height + 'px' })
      }
      self.timeline.setOptions({ height: height });
      setContainerHeight(container);
    });

    $visTimeline.on('click', '#edit-button_' + this.idVisualisation, function () {
      self.eventEdit($(this).parent().data('event-id'));
      self.sharedService.status = true;
    });
    $visTimeline.on('click', '#more-button_' + this.idVisualisation, function () {
      self.eventMore($(this).parent().data('event-id'), 'description');
      self.select(($(this).parent().data('event-id')));
    });
    $visTimeline.on('click', '#attachments-button_' + this.idVisualisation, function () {
      for (let i = 0; i < self.sharedService.eventdata.length; i++) {
        if (self.sharedService.eventdata[i].id == $(this).parent().data('event-id')) {
          if (self.sharedService.eventdata[i].files_count > 0) {
            self.eventMore($(this).parent().data('event-id'), 'files');
          }
        }
      }
    });
    $visTimeline.on('click', '#bill_cost-button_' + this.idVisualisation, function () {
      for (let i = 0; i < self.sharedService.eventdata.length; i++) {
        if (self.sharedService.eventdata[i].id == $(this).parent().data('event-id')) {
          self.eventMore($(this).parent().data('event-id'), 'bill_cost');
        }
      }
    });

    $visTimeline.on('click', '#comments-button_' + this.idVisualisation, function () {
      for (let i = 0; i < self.sharedService.eventdata.length; i++) {
        if (self.sharedService.eventdata[i].id == $(this).parent().data('event-id')) {
          if (self.sharedService.eventdata[i].comments_count > 0) {
            self.eventMore($(this).parent().data('event-id'), 'comments');
          }
        }
      }
    })

    $visTimeline.on('click', '#tags-button_' + this.idVisualisation, function () {
      for (let i = 0; i < self.sharedService.eventdata.length; i++) {
        if (self.sharedService.eventdata[i].id == $(this).parent().data('event-id')) {
          if (self.sharedService.eventdata[i].tags_count > 0) {
            self.eventMore($(this).parent().data('event-id'), 'tags');
          }
        }
      }
    });


    $visTimeline.on('click', '#custom_columns-button_' + this.idVisualisation, function () {
      for (let i = 0; i < self.sharedService.eventdata.length; i++) {
        if (self.sharedService.eventdata[i].id == $(this).parent().data('event-id')) {
          if (self.sharedService.eventdata[i].custom_field) {
            self.eventMore($(this).parent().data('event-id'), 'custom_columns');
          }
        }
      }
    });

  }

  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  eventEdit(id: number) {
    this.onEdit.emit(id);
  }

  eventMore(id: number, activeTab?) {
    this.onMore.emit({ id: id, activeTab: (activeTab ? activeTab : 'description') });
  }

  renderAndSetItems(events: any = this.events, saveCursor = false) {
    console.log('events',events)
    if (this.events && this.events.length != 0) {
      this.eventsRef = this.filterToTemplate.transformEvents(this.events);
    }
    this.maxDate = 0;
    this.minDate = 0;
    let self = this;
    if (this.eventsRef && this.eventsRef.length !== 0 && events.length !== 0) {
      events = this.eventsRef;
    }
    if (events.length > 0) {
      this.minDate = moment(events[0].start_date).valueOf();
    }
    let items = new vis.DataSet(events ? events.map(function (item, key) {
      item.start = item.start_date;
      if (moment(item.start_date).valueOf() < self.minDate) {
        self.minDate = moment(item.start_date);
      }
      if (moment(item.start_date).valueOf() > self.maxDate) {
        self.maxDate = moment(item.start_date);
      }
      if (item.if_start_days) {
        item.formatStartDate = moment(item.start_date).format('MMM YYYY');
      } else {
        item.formatStartDate = moment(item.start_date).format('MMM DD, YYYY');
      }
      item.monthYear = moment(item.start_date).format('MMMM YYYY');
      if (!item.description) item.description = '';
      return item;
    }) : []);
    this.timeline.setItems(items);
    this.changeLimits(saveCursor, this.getStartDate(events));
    this.showMonthYear();
    this.navigation.calculateFlags();
  }

  getStartDate(events) {
    let dates = [];
    events.forEach((order: any) => {
      dates.push(order.start_date);
    });
    let date_sort_asc = function (date1, date2) {
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    };
    dates.sort(date_sort_asc);
    return (dates[0]);
  }

  changeLimits(saveCursor: boolean = false, startDate, minDate = this.minDate, maxDate = this.maxDate) {
    if (startDate) {
      let date = new Date(startDate);
      date.setDate(date.getDate() - 3);
      let endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 4);
    }
    if (!minDate) {
      minDate = new Date();
    }
    if (this.timeline) {
      let diffTime = (moment(this.maxDate).valueOf() - moment(this.minDate).valueOf()) * 0.4;
      this.minDate = moment(minDate).valueOf() - diffTime;
      this.maxDate = moment(maxDate).valueOf() + diffTime;
      this.timeline.setOptions({
        maxHeight: "100%",
        zoomMin: this.zoomMin,
        margin: {
          item: {
            horizontal: -10
          },
          axis: 200
        }
      });
      if (!saveCursor) {
        let start = this.minDate,
          end = this.maxDate,
          interval = (end - start) * -0.3,
          pos = {
            start: start - interval,
            end: end + interval
          };
        this.timeline.setWindow(pos);
      }
      this.timeline.redraw();
      this._changeDetectorRef.detectChanges();
    }
  }

  showMonthYear(pos = null) {
    if (!pos) {
      pos = this.timeline.getWindow()
    }
    let middleDate = pos.start.valueOf() + ((pos.end.valueOf() - pos.start.valueOf()) / 2);
    jQuery('#nameMonth .month').text(moment(middleDate).format('MMMM YYYY'));
  };

  zoomPercent(percentage) {
    let range = this.timeline.getWindow(),
      start = range.start.valueOf(),
      end = range.end.valueOf(),
      interval = (range.end.valueOf() - range.start.valueOf()) * percentage,
      pos = {
        start: start - interval,
        end: end + interval
      };
    this.navigation.firstTime = true;
    this.timeline.setWindow(pos);
  };

  moveWindow(percentage) {
    let range = this.timeline.getWindow(),
      start = range.start.valueOf(),
      end = range.end.valueOf(),
      interval = (range.end.valueOf() - range.start.valueOf()) * percentage,
      pos = {
        start: start + interval,
        end: end + interval
      };
    this.navigation.firstTime = true;
    this.timeline.setWindow(pos);
  };

  select(id: number) {
    this.navigation.select(id);
  }
}
