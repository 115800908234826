import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare const turnstile: any; // Declare Turnstile globally

@Component({
  selector: 'app-turnstile',
  template: `<div id="turnstile-container"></div>`,
  styleUrls: ['./turnstile.component.css']
})
export class TurnstileComponent implements OnInit {
  @Input() siteKey!: string; // Cloudflare Turnstile Site Key
  @Output() tokenReceived = new EventEmitter<string>(); // Emits token to parent

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.loadTurnstile();
  }

  loadTurnstile() {
    // Check if Turnstile script is already loaded
    if (!document.querySelector('script[src*="challenges.cloudflare.com"]')) {
      const script = document.createElement('script');
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => this.renderTurnstile();
    } else {
      this.renderTurnstile();
    }
  }

  renderTurnstile() {
    turnstile.render('#turnstile-container', {
      sitekey: this.siteKey,
      callback: (token: string) => this.tokenReceived.emit(token), // Emit token
    });
  }
}
