import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TriallinesResourceService} from "../shared/triallines-resoruce.service";
import {User} from "../../../auth/shared/user.model";
import {AuthService} from "../../../auth/shared/auth.service";
import {ToastrService} from 'ngx-toastr';
import {Router} from "@angular/router";

declare var jQuery: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent
{
  @Output() onSubscribed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() user: any;

  modalId = '#subscription-modal';
  cardModalId = '#add-card-modal';

  processing: boolean = false;

  plan: string;

  constructor(
    public triallineResourceService: TriallinesResourceService,
    private auth: AuthService,
    public toasts: ToastrService,
    private router: Router
  ) {

  }

  createSubscription(planId) {

    this.processing = true;
    this.triallineResourceService.createSubscription(planId).toPromise()
      .then((r: any) => {

        this.toasts.success('You successfully subscribed to plan: ' + planId);

        this.processing = false;
        this.onSubscribed.emit(true);
        jQuery(this.modalId).modal('hide');
      })
      .catch((error) => {

        this.processing = false;
        let context = error.error;

        if (context.error.message) {

          jQuery(this.modalId).modal('hide');
          this.toasts.error(context.error.message);
          this.router.navigateByUrl('/profile/billing');
        }
      });
  }

  openCardModal() {

    jQuery(this.cardModalId).modal('show');
  }

  tryToSubscribe(planId) {

    this.plan = planId;
    this.createSubscription(planId);
    /*if (this.user.pm_last_four) {

      this.createSubscription(planId);
    } else {

      this.openCardModal();
    }*/
  }

  cardAdded(token) {

    jQuery(this.cardModalId).modal('hide');

    if (! this.plan) {

      this.toasts.info('Please select plan.');
    } else {

      this.createSubscription(this.plan);
    }
  }
}
