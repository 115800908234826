<!--[if lte IE 9]><p class="browserupgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</p><![endif]-->
<ng-container *ngIf='maintenanceMode == 1; else liveMode'>
    <router-outlet></router-outlet>
</ng-container>
<ng-template #liveMode>
<div class="header_height"></div>
<header class="navbar navbar-inverse navbar-fixed-top bs-docs-nav nav-down" role="banner">
    <div class="container-fluid menu_container">
        <div id="menu" class="navbar navbar-default " role="navigation">
            <div class="flex navigation_position">
                <div class="navbar-header">
                    <a class="navbar-brand"[routerLink]="['/']"><img src="assets/img/trialine_logo.png" width="195" height="51" alt=""></a>
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-menubuilder"><span class="sr-only">Toggle navigation</span><span class="icon-bar"></span><span class="icon-bar"></span><span class="icon-bar"></span> </button>
                </div>
                <div class="collapse navbar-collapse navbar-menubuilder">
                    <ul class="nav navbar-nav navbar-right">
                        <li><a class="nav_close_link" [routerLink]="['/']" fragment="home">Home</a> </li>
                        <li><a class="nav_close_link" [routerLink]="['/']" fragment="additional_features">Product Features</a> </li>
                        <li><a class="nav_close_link" [routerLink]="['/sample-triallines']" fragment="samples_holder">sample triallines</a> </li>
                        <li><a class="nav_close_link" [routerLink]="['/']" fragment="pricing_plans">Pricing</a> </li>
                        <li><a class="nav_close_link" target="_blank" href="https://blog.trialline.net/">Blog</a> </li>
                        <li><a class="nav_close_link" target="_blank" href="https://trialline.zohodesk.com/portal/en/home">Help Desk</a> </li>
                        <li><a class="nav_close_link" data-toggle="modal" data-target="#popap_form">Contact Us</a> </li>
                        <li><a class="nav_close_link" [routerLink]="['/login']">LOGIN</a></li>
                        <li><a class="nav_close_link" [routerLink]="['/']" fragment="contact">sign-up</a></li>
                        <li><a class="nav_close_link" href="https://calendly.com/triallinedemo/triallinedemo" target="_blank">schedule Demo</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
<router-outlet></router-outlet>
<footer id="footer">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <ul class="footer_menu">
                    <li><a [routerLink]="['/']" fragment="home">HOME</a></li>
                    <li><a [routerLink]="['/']" fragment="additional_features">FEATURES</a></li>
                    <li><a [routerLink]="['/sample-triallines']" fragment="samples_holder">SAMPLES</a></li>
                    <li><a [routerLink]="['/']" fragment="pricing_plans">PRICING</a></li>
                    <li><a data-toggle="modal" data-target="#popap_form">CONTACT US</a></li>
                    <li><a [routerLink]="['/login']">LOGIN</a></li>
                </ul>
            </div>
            <div class="col-xs-12 footer_copy">
                <p>Copyright © 2014-{{getYear()}} TrialLine
                  <!--by <a target="_blank" href="https://www.lawfather.net/">Law Father</a> -->
                   - All rights reserved.
                  <a target="_blank" href="https://blog.trialline.net/privacy-policy/"> Privacy Policy.</a>
                </p>
            </div>
            <div class="footer_logo"><img src="assets/img/trialine_logo.png" alt="" width="195" height="51" class="img-responsive"></div>
        </div>
    </div>
</footer>
</ng-template>
