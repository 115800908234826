import {AfterViewChecked, AfterViewInit, AfterContentInit, Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

import {Subscription} from "rxjs";

declare var jQuery: any;

@Component({
  selector: 'app-starter',
  templateUrl: './starter.component.html',
  styleUrls: [
    './starter.component.css'
  ]
})

export class StarterComponent implements OnDestroy {
  private subscription: Subscription;
  public maintenanceMode : number = 0;

  constructor(router: Router) {
    this.subscription = router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector("#" + tree.fragment);
          if (element) {
            element.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
          }
        }
      }
    });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {

    // var prevScrollpos = window.pageYOffset;
    // let iframe = document.getElementById("samples_holder");
    // if (!iframe) {
    //     window.onscroll = function () {
    //         var currentScrollPos = window.pageYOffset;
    //         const header = document.getElementById("header");
    //         if (currentScrollPos > 81) {
    //             if (header) {
    //                 header.style.top = prevScrollpos > currentScrollPos ? "0" : "-81px";
    //             }
    //             prevScrollpos = currentScrollPos;
    //         }
    //     }
    // }

    document.ondblclick = function () {
      return false;
    }
  }

  getYear(){
    return new Date().getFullYear();
  }
}
