import { ChangeDetectorRef, Component, forwardRef, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Team } from "../shared/team.model";
import { UserProfile } from "../../profile/shared/user.model";
import { permissions } from "../../../../../.env";
import { MainComponent } from "../../../layouts/main/main.component";
import { TeamResourceService } from "../shared/team-resoruce.service";
import { Modal } from 'ngx-modialog-7/plugins/bootstrap';
import { NgForm } from "@angular/forms";
import { CouponResourceService } from '../../profile/shared/coupon-resoruce.service';

declare var jQuery: any;

@Component({
  selector: 'app-team-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})

export class ManageComponent implements OnInit {

  team: Team;

  showTeammateForm: boolean = false;

  permissions = permissions;

  createTeammateId = '#new-teammate';
  deleteTeammateId = '#delete-teammate';
  user_id: any;
  user: any;
  userflagfordisplay: string;
  subscription_plan: any;
  subscription_amount: string;
  user_plan: any;
  edituser: any;
  editmodal: boolean = false;
  errors: {};
  memebrid: any;
  eventvalue: any;
  userinfo : any;
  newPricingButton: boolean = true;
  selectedUserIndex: number;
  processing: boolean;
  isMonthInputVisible: boolean = false;
  isYearInputVisible: boolean = false;
  monthCouponCode: string = '';
  yearCouponCode: string = '';
  isCouponApplied: boolean = false;
  isCouponInputValid = false;
  isCouponCodeEnteredMonth: boolean = false;
  isCouponCodeEnteredYear: boolean = false;
  closeCouponInputAfterClick: boolean = true;
  couponObj = {
    planId: '',
    planPrice: 0,
    discountedAmount: 0,
    finalAmount: 0,
    active: false,
    percentageDiscount: 0
  };
  response: any = null;

  constructor(
    public modal: Modal,
    protected toasts: ToastrService,
    protected title: Title,
    protected route: ActivatedRoute, public router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private teamResourceService: TeamResourceService,
    private couponResourceService: CouponResourceService,
    @Inject(forwardRef(() => MainComponent)) public _layout: MainComponent
  ) { title.setTitle('Trialline | Team') }

  ngOnInit() {
    console.log('this.team')
    this.route.data.subscribe((data: { team: Team }) => {
      this.team = data.team;
    });
    console.log(this.team)
  }


  ngOnDestroy() {
    this._changeDetectorRef.detach();
  }

  toggleCouponInput(type: 'year' | 'month') {
    if (type === 'year') {
      this.isYearInputVisible = !this.isYearInputVisible;
      this.isMonthInputVisible = false;
      this.yearCouponCode = '';
      this.closeCouponInputAfterClick = false;
    } else if (type === 'month') {
      this.isMonthInputVisible = !this.isMonthInputVisible;
      this.isYearInputVisible = false;
      this.monthCouponCode = '';
      this.closeCouponInputAfterClick = false;
    }
  }

  closeCouponInput() {
    if (this.closeCouponInputAfterClick) {
      this.isYearInputVisible = false;
      this.isMonthInputVisible = false;
      this.closeCouponInputAfterClick = false;
    } else {
      this.closeCouponInputAfterClick = true;
    }
  }


  isMonthCouponApplied(): boolean {
    return this.monthCouponCode && this.monthCouponCode.trim() !== '';
  }

  isYearCouponApplied(): boolean {
    return this.yearCouponCode && this.yearCouponCode.trim() !== '';
  }

  onCouponCodeChangeMonth() {
    this.isCouponCodeEnteredMonth = !!this.monthCouponCode;
  }

  onCouponCodeChangeYear() {
    this.isCouponCodeEnteredYear = !!this.yearCouponCode;
  }


  removeCoupon() {
    this.couponObj = {
      planId: '',
      planPrice: 0,
      discountedAmount: 0,
      finalAmount: 0,
      active: false,
      percentageDiscount: 0
    };
    this.isCouponApplied = false;
    // this.isCouponInputValid = false;
    this.monthCouponCode = '';
    this.yearCouponCode = '';
    this.isYearInputVisible = false;
    this.isMonthInputVisible = false;
  }

  couponUpdatePlanId(planPrice) {
    switch(planPrice) {
      case 499:
        return 'annual_499_per_year'
      break;
      case 59:
        return 'month_59_per_month'
      break;
    }
  }

  checkCoupon(couponvalue, planPrice) {
    // if (!couponvalue || couponvalue.trim() === '') {
    //   this.toasts.error('Coupon code should not be empty');
    //   return;
    // }
  
    this.couponResourceService.getCouponByName(couponvalue).toPromise()
      .then((response: any) => {
        
        this.couponObj.planId = this.couponUpdatePlanId(planPrice);
        this.couponObj.planPrice = planPrice;
        this.couponObj.discountedAmount = planPrice * response.body.coupon.percent_off / 100;
        this.couponObj.percentageDiscount = response.body.coupon.percent_off;
        this.couponObj.finalAmount = planPrice - this.couponObj.discountedAmount;
        this.couponObj.active = true;
        this.toasts.success(response.body.success);
        this.isCouponApplied = true;
      })
      .catch(err => {
        this.toasts.error(err.error.error);
      });
  }

  openTeammateModal() {
    this.showTeammateForm = true;
    this._changeDetectorRef.detectChanges();
    jQuery(this.createTeammateId).modal('show');
  }
  confirmedDeleteSubscription(user: any) {

  const dialogRef = this.modal.confirm()
  .size('lg')
  .isBlocking(true)
  .showClose(true)
  .keyboard(27)
  .dialogClass('in')
  .title('Are you sure?')
  .body('Are you sure you want to Delete Staff Member?')
  .open()
  dialogRef.result
  .catch((err: any) => {console.log('ERROR: ' + err)
    this.modelClose()
  })
  .then((dialog: any) => {
    this.modelClose()
    if(dialog){
      this.deleteTeammate(user)
    }
  });
  this.modelAction();
}

  deleteTeammate(user: any) {

    this.teamResourceService.deleteTeammate(user.id).toPromise()
      .then(r => {

        let index = this.team.users.indexOf(this.team.users.find(u => u.id == user.id));
        this.team.users.splice(index, 1);
        this.toasts.success('Successfully deleted');
      })
  }

  addTeammate(user: UserProfile) {
    // this.team.users.push(user);
    this.teamResourceService.getTeam().toPromise().then((data) => {
      this.team.users= data.users;
    })
    this._changeDetectorRef.detectChanges();
  }
  resendTeammate(user: UserProfile) {
    this.teamResourceService.resendInvitation(user).toPromise()
      .then((user: any) => {

        this.errors = {};

        this.toasts.success('Resend Account Invitation successfully');
      })
      .catch((error) => {

        if (error.status == 422) {
          let errorData = error.error;
          this.errors = errorData;
          console.log(this.errors);

          try {
            for (let key in errorData) {
              let value = errorData[key];
              this.toasts.error(value.join(" "), key);
            }
          } catch (e) {
            console.log(e)
          }
        } else if (error.status == 403) {
          this.toasts.error(error.error.error.message)
        } else {
          this.toasts.error(error);
        }
      });
  }

  /*Rheal Test101 */

  ChangeRole(member_id, event, user) {
    this.memebrid = member_id;
    this.eventvalue = event.target.value;
    if (event.target.value == 5 && user.user_type != 5) {
      this.toasts.error('You can\'t change a user role as Guest User.');
      this.reloadteam()
    } else {
      if (event.target.value != 5 && user.user_type == 5) {
        jQuery('#guestusermodalchange').modal('show');
      } else {

        this.teamResourceService.UpdateUserRole(member_id, event.target.value).toPromise()
          .then(r => {
            this.toasts.success('Team Member Role Successfully Changed. ');
          })
      }
    }
  }
  confirmchangerole() {
    this.teamResourceService.UpdateguestUserRole(this.memebrid, this.eventvalue).toPromise()
      .then(r => {
        this.toasts.success('Team Member Role Successfully Changed. ');
        this.reloadteam()
        jQuery('#guestusermodalchange').modal('hide');

      })
  }
  reloaduser() {
    this.reloadteam()
  }

  subscribe(user, index) {
    console.log("old");
    this.selectedUserIndex = index;
    this.user_id = user.id;
    if ((user.subscriptions.length == 0)) {
      this.userflagfordisplay = '';
    }
    else if (user.subscription_plan == 'month') {
      this.userflagfordisplay = 'month';
    } else {
      this.userflagfordisplay = 'annual';
    }
    jQuery('#subscriptionusermodalNew').modal('show')
  }
  hideLoginUserData(user) {
    return localStorage.getItem("user_email") == user.email;
  }

  subscribe2022(user, index) {
    console.log("new", user);
    console.log('index', index);
    this.selectedUserIndex = index;
    this.userinfo = '';
    this.user_id = user.id;
    let usersubscriptions = user.subscriptions[0] ? user.subscriptions[0].stripe_plan:false;
    console.log("usersubscriptions", usersubscriptions);
    if (usersubscriptions == 'month_59_per_month') {
      this.userflagfordisplay = 'month_59_per_month';
    } else if (usersubscriptions == 'month_59_per_month_team') {
      this.userflagfordisplay = 'month_59_per_month_team';
    } else if (usersubscriptions == 'annual_499_per_year_team') {
      console.log('true');
      this.userflagfordisplay = 'annual_499_per_year_team';
    }
    else {
      this.userflagfordisplay = 'annual_499_per_year';
    }
    this.userinfo = user;
    jQuery('#subscriptionusermodal').modal('hide')
    jQuery('#subscriptionusermodalNew').modal('show')
  }

  displayNewPricingPlan() {
    this.newPricingButton = true;
  }


/*Softobiz 12jul22 */

  tryToSubscribeteammate(plan) {
    this.subscription_plan = ''
    this.subscription_amount = '';
    console.log("case1")
    console.log('plan', plan);
    this.user_plan = plan;
    if (plan == 'month') {
      this.subscription_plan = 'Month to Month'
      this.subscription_amount = '$49';
    } else if (plan == 'annual') {
      this.subscription_plan = 'Annual'
      this.subscription_amount = '$348';
    } else if (plan == 'month_59_per_month_team') {
      this.subscription_plan = 'Month to Month'
      this.subscription_amount = '$59';
    } else if (plan == 'annual_499_per_year_team') {
      this.subscription_plan = 'Annual'
      this.subscription_amount = '$499';
    }
    //this.subscription_plan=plan
    // @ts-ignore
    if (this.team.owner.pm_last_four) {
      jQuery('#subscribemodal').modal('show')
    } else {
      this.toasts.error("Owner not added card information yet.")
    }

  }
  confirmsubscription() {
    this.processing = true;
    let couponCodeToSend: string;

  if (this.isYearInputVisible) {
    couponCodeToSend = this.yearCouponCode;
  } else if (this.isMonthInputVisible) {
    couponCodeToSend = this.monthCouponCode;
  } else {
    couponCodeToSend = '';
  }
    var data = [];
    data.push({ 'plan': this.user_plan, 'user_id': this.user_id, 'couponCode': couponCodeToSend })
    this.teamResourceService.subscribeteammate(data).toPromise()
      .then(r => {
        console.log("data", data);
        this.processing= false;
        
        // this.userflagfordisplay=
        this.reloadteam()
        jQuery('#subscribemodal').modal('hide')
        jQuery('#subscriptionusermodal').modal('hide')
        this.toasts.success('User subscribed successfully .');
        console.log('subscription plan', this.subscription_plan)
        if(this.subscription_plan =='Annual'){
          this.userflagfordisplay = 'annual_499_per_year_team'
        }if (this.subscription_plan == 'Month to Month'){
            this.userflagfordisplay ='month_59_per_month_team'
        }
      })


  }
  unsubscribe() {
    jQuery('#subscriptionusermodal').modal('hide')
    jQuery('#cancelmodal').modal('show')
  }

  unsubscribeNew() {
    jQuery('#subscriptionusermodalNew').modal('hide')
    jQuery('#cancelmodal').modal('show')
  }
  confirmsubscribe(user) {
      console.log("user",user);

    var data = []
    data.push({ 'user_id': this.user_id })
    this.teamResourceService.unsubscribeteammate(data).toPromise()
      .then(r => {
        this.reloadteam()
        this.toasts.success('User subscription cancel successfully');
        jQuery('#cancelmodal').modal('hide');
      })
  }
  editTeammate(user) {
    this.editmodal = true;
    this.edituser = user;
    jQuery("#edit-teammate").modal('show');
  }
  editTeammateconfirm(form: NgForm) {
    this.teamResourceService.updateTeammate(form.value).toPromise()
      .then((user: any) => {

        this.errors = {};
        //form.reset();
        jQuery('#edit-teammate').modal('hide');
        //this.onCreated.emit(user);
        this.toasts.success('Teammate successfully updated');
        this.reloadteam()

      })
      .catch((error) => {

        if (error.status == 422) {
          let errorData = error.error;
          this.errors = errorData;
          console.log(this.errors);

          try {
            for (let key in errorData) {
              let value = errorData[key];
              this.toasts.error(value.join(" "), key);
            }
          } catch (e) {
            console.log(e)
          }
        } else if (error.status == 403) {
          this.toasts.error(error.error.error.message)
        } else {
          this.toasts.error('Email already exists! Please try with a different Email.');
        }
      });
  }

  reloadteam() {
    this.teamResourceService.getTeam().toPromise()
      .then(r => {
        console.log("r",r);
        // @ts-ignore
        this.team = r;
        this._changeDetectorRef.detectChanges();
        //this._changeDetectorRef.detach();
      })
  }
  modelClose() {
    $('body').removeClass('modal-open');
  }
  modelAction() {
    $('bs-modal-container').addClass('show-midal-element').addClass('show-midal-element-sub');
  }
}
